import React from "react";
import { useSelector } from "react-redux";
import NavBar from "../components/Dashboard/NavBar/NavBar";
import SideNav from "../components/Dashboard/SideNav/SideNav";
import LoadingPage from "./Loading/LoadingPage";
import "./Main.css";

function Main({ children }) {
  const currentUser = useSelector((state) => state.userReducer.user);
  const isAuth = useSelector((state) => state.userReducer.isAuth);

  return isAuth ? (
    <div className="Main">
      <NavBar currentUser={currentUser} />
      <section className="Main__Container">
        <div className="Main__Container--SideNav">
          <SideNav />
        </div>

        <div className="Main__Container--content">{children}</div>
      </section>
    </div>
  ) : (
    <div className="Main">
      <LoadingPage />
    </div>
  );
}

export default Main;
