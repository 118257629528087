import { Route, Routes } from "react-router-dom";
import "./App.css";
import Register from "./components/Starter/Register/Register";
import Login from "./components/Starter/Login/Login";
import SideMenu from "./components/Starter/SideMenu/SideMenu";
import Notification from "./components/Starter/Alerts/Notification";
import PrivateRoute from "./PrivateRoute/PrivateRoute";
import Main from "./Pages/Main";
import useWindowDimensions from "./hooks/useWindowDimensions";
import NotSupportedPage from "./Pages/ScreenNotSupported/NotSupportedPage";
import LoadingPage from "./Pages/Loading/LoadingPage";
import CompTech from "./components/Dashboard/CompTech/CompTech";

function App(props) {
  const { height, width } = useWindowDimensions();

  return width < 1280 && height < 712 ? (
    <NotSupportedPage />
  ) : (
    <div className="StarterMenu">
      <Notification />
      <Routes>
        <Route
          path="/"
          element={
            <>
              <PrivateRoute>
                <Main>
                  <Notification />
                  <CompTech />
                </Main>
              </PrivateRoute>
            </>
          }
        />

        <Route
          path="/monCompte"
          element={
            <PrivateRoute>
              <Main>
                <h4>Mon compte</h4>
              </Main>
            </PrivateRoute>
          }
        />
        <Route
          path="/login"
          element={
            <>
              <SideMenu />
              <Login />
            </>
          }
        />
        <Route
          path="/register"
          element={
            <>
              <SideMenu />
              <Register />
            </>
          }
        />
        <Route
          path="/reset"
          element={
            <>
              <SideMenu />
              <Register />
            </>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
