import {
  NEW_NOTIFICATION,
  CLEAR_NOTIFICATION,
  CLEAR_ALL_NOTIFICATION,
} from "../actions/actionTypes";

const initState = [];
export const notificationReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case NEW_NOTIFICATION:
      state.push({ code: payload.code, message: payload.message });
      return state;

    case CLEAR_NOTIFICATION:
      return state;

    case CLEAR_ALL_NOTIFICATION:
      return initState;

    default:
      return state;
  }
};
