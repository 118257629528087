import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { logout } from "../../../RSMS/actions/userActions";
import "./NavBar.css";

function NavBar() {
  const currentUser = useSelector((state) => state.userReducer.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <nav className="NavBar">
      <button className="NavBar__userSec">
        <div className="NavBar__userSec--dropdown">
          <p className="NavBar__userSec--dropdown--cut"></p>
          <div>
            {" "}
            <Link className="NavBar__userSec--dropdown--item" to="/monCompte">
              Mon compte
            </Link>
            <div className="NavBar__userSec--dropdown--item">Mon équipe</div>
            <div className="NavBar__userSec--dropdown--item">
              Gestion des équipes
            </div>
            <div className="NavBar__userSec--dropdown--item">
              Campagne marketing
            </div>
            <div className="NavBar__userSec--dropdown--item">
              Gestion des techniciens
            </div>
            <div className="NavBar__userSec--dropdown--item">
              Ticket manager
            </div>
            <div className="NavBar__userSec--dropdown--item">HELPDESK</div>
            <div className="NavBar__userSec--dropdown--item">
              Carnet d'adresses
            </div>
            <div className="NavBar__userSec--dropdown--item">Statistiques</div>
            <div className="NavBar__userSec--dropdown--item">
              Gestion des NEWS
            </div>
            <div className="NavBar__userSec--dropdown--item">
              Gestion des FAQ
            </div>
            <div className="NavBar__userSec--dropdown--item">
              GESTION MASTER
            </div>
            <div
              className="NavBar__userSec--dropdown--item"
              onClick={() => dispatch(logout(navigate))}
            >
              Déconnexion{" "}
            </div>
          </div>
        </div>

        <div className="NavBar__userSec--avatar">{`${
          currentUser.prenom[0].toUpperCase() + currentUser.nom[0].toUpperCase()
        }`}</div>
        <h3 className="NavBar__userSec--Name">{`${currentUser.prenom} ${currentUser.nom}`}</h3>

        <svg
          aria-hidden="true"
          focusable="false"
          data-icon="angle-down"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 320 512"
        >
          <path
            fill="currentColor"
            d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z"
          ></path>
        </svg>
      </button>
      <div className="NavBar__serchSec"> </div>
      <div className="NavBar__btnsSec"> </div>
    </nav>
  );
}

export default NavBar;
