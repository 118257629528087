import "./Register.css";
import logoS30 from "../../../assets/Starter/SideMenu/logoS30.png";

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Input from "@mui/material/Input";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { RegisterUserHandler } from "../../../RSMS/actions/userActions";

function Register({ setNotifs }) {
  const dispatch = useDispatch();

  //REGEX EXPRESSION FOR VALIDATION
  const MailReg = new RegExp(
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
  );
  const PwdReg = new RegExp(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&])(?=.{8,})/
  );

  //INPUT SET UP USESTATE HOOK
  const [nomInput, setNomInput] = useState("");
  const [prenomInput, setPrenomInput] = useState("");
  const [emailInput, setEmailInput] = useState("");
  const [pwdInput, setPwdInput] = useState("");

  const [btnLoading, setBtnLoading] = useState(false);
  const [pwdShow, setPwdShow] = useState(false);

  const [err, setErr] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [resCode, setResCode] = useState("");

  //----------------------------//
  // FUNCTIONS HANDLERS
  //----------------------------//

  // PASSWORD SHOW FUNC
  const handleClickShowPassword = () => {
    setPwdShow(!pwdShow);
  };

  // REGISTRATION HANDLER
  const RegisterBtnHandler = (e) => {
    e.preventDefault();
    setBtnLoading(true);

    // INPUT VALIDATIONS
    // EMAIL INPUT VALIDATION
    if (!MailReg.test(emailInput)) {
      setBtnLoading(true);
      setErr(true);
      setBtnLoading(false);
      return setErrMsg("Oups! Adresse e-mail invalide");
    }

    // PASSWORD INPUT VALIDATION
    if (!PwdReg.test(pwdInput)) {
      setBtnLoading(true);
      setErr(true);
      setBtnLoading(false);
      return setErrMsg(
        "Oups! votre mot de passe doit contenir au minimum 8 caractères, une lettre minuscule, une lettre majuscule, un caractère spécial et un chiffre"
      );
    }

    // REGISTRATION DIPATCHING FUN
    dispatch(
      RegisterUserHandler(
        emailInput,
        pwdInput,
        nomInput,
        prenomInput,
        setErrMsg,
        setErr,
        setBtnLoading,
        setResCode,
        setNomInput,
        setPrenomInput,
        setEmailInput,
        setPwdInput
      )
    );
  };

  return (
    <div className="Register">
      <img src={logoS30} alt="logo S30" className="Register__Header" />
      <div className="Register__Main">
        <h2 className="Register__Main--Header">Créer un compte GESTS30</h2>

        <h3 className="Register__Main--text">
          Votre compte doit etre <span> valider </span> et{" "}
          <span> affecter </span> à une équipe par votre responsable pour
          pouvoir utiliser GESTS30.
        </h3>
      </div>
      <div className="Register__Form">
        <h4
          className={
            resCode === 200 ? "Register__Form--success" : "Register__Form--err"
          }
        >
          {errMsg ? errMsg : ""}
        </h4>
        <div className="Register__Form--input">
          <TextField
            error={err ? true : false}
            id="standard-error-helper-text"
            label="Nom"
            value={nomInput}
            onClick={() => {
              setErr(false);
              setErrMsg("");
            }}
            onChange={(e) => setNomInput(e.target.value)}
            variant="standard"
            sx={{ minWidth: "90%", paddingBottom: "15px" }}
            InputLabelProps={{
              style: {
                fontSize: "1.5rem",
                fontWeight: 500,
                color: "#6b6b6b",
              },
            }}
            inputProps={{
              style: {
                fontSize: "1.7rem ",
                fontWeight: 500,
                color: "#6b6b6b",
              },
            }}
          />
          <TextField
            error={err ? true : false}
            id="standard-error-helper-text"
            label="Prenom"
            value={prenomInput}
            onChange={(e) => setPrenomInput(e.target.value)}
            variant="standard"
            onClick={() => {
              setErr(false);
              setErrMsg("");
            }}
            sx={{ minWidth: "90%", paddingBottom: "15px" }}
            InputLabelProps={{
              style: {
                fontSize: "1.5rem",
                fontWeight: 500,
                color: "#6b6b6b",
              },
            }}
            inputProps={{
              style: {
                fontSize: "1.7rem ",
                fontWeight: 500,
                color: "#6b6b6b",
              },
            }}
          />
          <TextField
            error={err ? true : false}
            id="standard-error-helper-text"
            label="Adresse mail"
            value={emailInput}
            onClick={() => {
              setErr(false);
              setErrMsg("");
            }}
            onChange={(e) => setEmailInput(e.target.value)}
            variant="standard"
            sx={{ minWidth: "90%", paddingBottom: "15px" }}
            InputLabelProps={{
              style: {
                fontSize: "1.5rem",
                fontWeight: 500,
                color: "#6b6b6b",
              },
            }}
            inputProps={{
              style: {
                fontSize: "1.7rem ",
                fontWeight: 500,
                color: "#6b6b6b",
              },
            }}
          />

          <FormControl
            variant="standard"
            sx={{ minWidth: "90%", paddingBottom: "40px" }}
          >
            <InputLabel
              htmlFor="standard-adornment-password"
              sx={{ fontSize: "1.5rem", fontWeight: 500, color: "#6b6b6b" }}
            >
              Password
            </InputLabel>
            <Input
              error={err ? true : false}
              sx={{
                fontSize: "1.5rem",
                fontWeight: 500,
                color: "#6b6b6b",
              }}
              label="Mot de passe"
              size="20px"
              value={pwdInput}
              onClick={() => {
                setErr(false);
                setErrMsg("");
              }}
              id="standard-adornment-password"
              type={pwdShow ? "text" : "password"}
              onChange={(e) => setPwdInput(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                  >
                    {pwdShow ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </div>
        <div className="Register__Main--footer">
          <Link to="/login" className="Register__Main--footer--a">
            {" "}
            Vous avez un compte CCA{" "}
          </Link>

          <LoadingButton
            onClick={(e) => RegisterBtnHandler(e)}
            endIcon={<SendIcon />}
            loading={btnLoading}
            loadingPosition="end"
            variant="contained"
            sx={{ bgcolor: "#00517c", width: "13rem" }}
          >
            Sign In
          </LoadingButton>
        </div>
      </div>
    </div>
  );
}

export default Register;
