import { Switch } from "@mui/material";
import React from "react";
import "./SideNav.css";

function SideNav() {
  return (
    <div className="SideNav__Main">
      <div className="SideNav__Main--TOP">
        <div className="SideNav__Main--TOP-btn">
          <Switch color="success" />
          <div className="SideNav__Main--TOP-btn-TEXT">
            <h3>Tickets Perso</h3>
            <p>Restreint la volumétrie aux tickets pris en charge, créés.</p>
          </div>
        </div>
      </div>
      <h4>GESTS30</h4>
    </div>
  );
}

export default SideNav;
