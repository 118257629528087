//LOGIN ACTION TYPES
export const SEND_LOGIN = "SEND_LOGIN";
export const SEND_LOGIN_OK = "SEND_LOGIN_OK";
export const SEND_LOGIN_NOK = "SEND_LOGIN_NOK";

export const CHECK_ISAUTH = "CHECK_ISAUTH";
export const LOG_OUT = "LOG_OUT";
//REGISTER ACTION TYPES
export const SEND_REGISTER = "SEND_REGISTER";
export const SEND_REGISTER_OK = "SEND_REGISTER_OK";
export const SEND_REGISTER_NOK = "SEND_REGISTER_NOK";

//NOTIFICATION ACTION TYPES
export const NEW_NOTIFICATION = "NEW_NOTIFICATION";
export const CLEAR_NOTIFICATION = "CLEAR_NOTIFICATION";
export const CLEAR_ALL_NOTIFICATION = "CLEAR_ALL_NOTIFICATION";
