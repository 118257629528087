import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router";
import { checkIsAuth } from "../RSMS/actions/userActions";

const PrivateRoute = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const isAuth = useSelector((state) => state.userReducer.isAuth);

  if (!isAuth && token) {
    dispatch(checkIsAuth(token, navigate));
  }
  if (isAuth && !token) {
    return <Navigate to="/login" />;
  }

  return isAuth || token ? children : <Navigate to="/login" />;
};

export default PrivateRoute;
