import axios from "axios";
import { LOG_OUT, NEW_NOTIFICATION, SEND_LOGIN_OK } from "./actionTypes";
// import { v4 as uuidv4 } from "uuid";

//--------------------//
//LOGIN FUNC HANDLER

export const loginUserHandler =
  (email, pwd, navigate, setBtnLoading, setErr, setErrMsg) =>
  async (dispatch) => {
    try {
      const user = await axios.post("/api/users/auth", {
        email,
        pwd,
      });
      dispatch({
        type: NEW_NOTIFICATION,
        payload: { code: 200, message: `Bienvenue ${user.data.prenom}` },
      });
      dispatch({
        type: SEND_LOGIN_OK,
        payload: { ...user.data },
      });
      navigate("/");
    } catch (error) {
      setBtnLoading(false);
      setErr(true);
      setErrMsg(error.response.data.message);
      dispatch({
        type: NEW_NOTIFICATION,
        payload: {
          code: error.response.data.code,
          message: error.response.data.message,
        },
      });
    }
  };

//--------------------//
//REGISTER FUNC HANDLER
export const RegisterUserHandler =
  (
    email,
    pwd,
    nom,
    prenom,
    setErrMsg,
    setErr,
    setBtnLoading,
    setResCode,
    setNomInput,
    setPrenomInput,
    setEmailInput,
    setPwdInput
  ) =>
  async (dispatch) => {
    try {
      const user = await axios.post("/api/users/Register", {
        email,
        pwd,
        nom,
        prenom,
      });
      dispatch({
        type: NEW_NOTIFICATION,
        payload: { code: 200, message: user.message },
      });
      setResCode(user.data.code);
      setBtnLoading(false);
      setErrMsg(user.data.message);
      setNomInput("");
      setPrenomInput("");
      setEmailInput("");
      setPwdInput("");
    } catch (error) {
      setBtnLoading(false);
      setResCode(error.response.data.code);
      setErr(true);
      setErrMsg(error.response.data.message);
      dispatch({
        type: NEW_NOTIFICATION,
        payload: {
          code: error.response.data.code,
          message: error.response.data.message,
        },
      });
    }
  };

//--------------------//
//CHECK IF AUTH FUNC HANDLER
export const checkIsAuth = (token, navigate) => async (dispatch) => {
  try {
    const user = await axios.post("/api/users/isAuth", {
      token,
    });

    dispatch({
      type: NEW_NOTIFICATION,
      payload: { code: 200, message: `Bienvenue ${user.data.prenom}` },
    });
    dispatch({
      type: SEND_LOGIN_OK,
      payload: { ...user.data },
    });
  } catch (error) {
    navigate("/login");
    dispatch({
      type: NEW_NOTIFICATION,
      payload: {
        code: error.response.data.code,
        message: error.response.data.message,
      },
    });
  }
};

//--------------------//
//LOGOUT FUNC HANDLER

export const logout = (navigate) => async (dispatch) => {
  dispatch({
    type: LOG_OUT,
  });
  navigate("/login");
};
