import { SEND_LOGIN_OK, CHECK_ISAUTH, LOG_OUT } from "../actions/actionTypes";

const reducerInitState = {
  isAuth: false,
  user: {},
};

export const userReducer = (state = reducerInitState, { type, payload }) => {
  switch (type) {
    case SEND_LOGIN_OK:
      localStorage.setItem("token", payload.Token);
      return { isAuth: true, user: { ...payload } };
    case CHECK_ISAUTH:
      localStorage.setItem("token", payload.Token);
      return { isAuth: true, user: { ...payload } };
    case LOG_OUT:
      localStorage.removeItem("token");
      return { isAuth: false, user: {} };
    default:
      return state;
  }
};
