import React from "react";
import "./SideMenu.css";
import ParticlesBg from "particles-bg";
import logoFree from "../../../assets/Starter/SideMenu/logoFREE.png";

function SideMenu() {
  return (
    <>
      <div className="SideMenu">
        {" "}
        <ParticlesBg color="#ffffff" num={90} type="cobweb" bg={false} />
        <div className="SideMenu-main">
          <h2 className="SideMenu__AppName">GESTS30</h2>
          <h3 className="SideMenu__AppSlogon">
            La gestion de la conduite d'activité <span> facile </span>
          </h3>
          <h3 className="SideMenu__FreeLogo">
            MADE FOR
            <img
              src={logoFree}
              alt="FREE"
              className="SideMenu__FreeLogo--img"
            />
          </h3>
          <h3 className="SideMenu__AppVersion">
            Version <span>BETA</span>
          </h3>{" "}
        </div>
      </div>
    </>
  );
}

export default SideMenu;
