import { Alert, createTheme, Stack, ThemeProvider } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import "./Notification.css";

function Notification() {
  const notifiacations = useSelector((state) => state.notificationReducer);
  const theme = createTheme({
    typography: {
      htmlFontSize: 12,
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <div className="notification">
        {" "}
        <Stack spacing={2}>
          {notifiacations &&
            notifiacations.map((notif) => {
              return (
                <Alert severity={notif.code === 200 ? "success" : "error"}>
                  {notif.message}
                </Alert>
              );
            })}
        </Stack>
      </div>
    </ThemeProvider>
  );
}

export default Notification;
